/* corinthia-regular - latin */
@font-face {
  font-family: 'Corinthia';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/corinthia-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/corinthia-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/corinthia-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/corinthia-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/corinthia-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/corinthia-v9-latin-regular.svg#Corinthia') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/source-sans-pro-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/source-sans-pro-v21-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/source-sans-pro-v21-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}