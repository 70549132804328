@import '/node_modules/baguettebox.js/dist/baguetteBox.min.css';
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";
@import "fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-image-hero {
  background-image: url('../images/hero.png');
}

.webp .bg-image-hero {
  background-image: url('../images/hero.png?as=webp');
}

@layer utilities {
  .bg-gradient-54d {
    background: linear-gradient(54deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 64%); 
  }

  .bg-gradient-59d {
    background: linear-gradient(59deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 62%); 
  }

  .bg-gradient-34d {
    background: linear-gradient(34deg, rgba(255,255,255,1) 39%, rgba(255,255,255,0) 68%); 
  }

  .font-cursive {
    font-family: 'Corinthia', cursive;
  }
}

.gallery .glide__slide:not(.glide__slide--active) a::before {
  @apply -rotate-12;
}

.gallery .glide__slide--active a::before {
  @apply scale-105;
}

.gallery .glide__slide--active ~ .glide__slide a::before {
  @apply rotate-12;
}

.gallery .glide__bullet--active > div {
  @apply p-4;
}

.gallery .glide__bullet--active img {
  @apply h-48;
}

#prodigy-powered.w-0.h-0 a {
  @apply hidden;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}