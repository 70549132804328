#baguetteBox-overlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #000c;
  transition: opacity .5s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
  position: relative;
}

#baguetteBox-overlay .full-image figure {
  height: 100%;
  margin: 0;
  display: inline;
}

#baguetteBox-overlay .full-image img {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  box-shadow: 0 0 8px #0009;
}

#baguetteBox-overlay .full-image figcaption {
  width: 100%;
  text-align: center;
  white-space: normal;
  color: #ccc;
  background-color: #0009;
  font-family: sans-serif;
  line-height: 1.8;
  display: block;
  position: absolute;
  bottom: 0;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  height: 50%;
  width: 1px;
  margin-right: -1px;
  display: inline-block;
}

#baguetteBox-slider {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s, transform .4s, -webkit-transform .4s, -moz-transform .4s;
  position: absolute;
  top: 0;
  left: 0;
}

#baguetteBox-slider.bounce-from-right {
  animation: bounceFromRight .4s ease-out;
}

#baguetteBox-slider.bounce-from-left {
  animation: bounceFromLeft .4s ease-out;
}

@keyframes bounceFromRight {
  0%, 100% {
    margin-left: 0;
  }

  50% {
    margin-left: -30px;
  }
}

@keyframes bounceFromLeft {
  0%, 100% {
    margin-left: 0;
  }

  50% {
    margin-left: 30px;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  width: 44px;
  height: 60px;
  top: calc(50% - 30px);
}

.baguetteBox-button {
  cursor: pointer;
  color: #ddd;
  background-color: #32323280;
  border: 0;
  border-radius: 15%;
  outline: 0;
  margin: 0;
  padding: 0;
  font: 1.6em sans-serif;
  transition: background-color .4s;
  position: absolute;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: #323232e6;
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  width: 30px;
  height: 30px;
  top: 20px;
  right: calc(2% + 6px);
}

.baguetteBox-button svg {
  position: absolute;
  top: 0;
  left: 0;
}

.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.baguetteBox-double-bounce1, .baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  opacity: .6;
  background-color: #fff;
  border-radius: 50%;
  animation: bounce 2s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

@font-face {
  font-family: Corinthia;
  font-style: normal;
  font-weight: 400;
  src: url("corinthia-v9-latin-regular.aaa2f41c.eot");
  src: local(""), url("corinthia-v9-latin-regular.aaa2f41c.eot#iefix") format("embedded-opentype"), url("corinthia-v9-latin-regular.5353d960.woff2") format("woff2"), url("corinthia-v9-latin-regular.33988f10.woff") format("woff"), url("corinthia-v9-latin-regular.992b7a1a.ttf") format("truetype"), url("corinthia-v9-latin-regular.e86101a7.svg#Corinthia") format("svg");
  font-display: swap;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 300;
  src: url("source-sans-pro-v21-latin-300.7f3ff202.eot");
  src: local(""), url("source-sans-pro-v21-latin-300.7f3ff202.eot#iefix") format("embedded-opentype"), url("source-sans-pro-v21-latin-300.5ee120b3.woff2") format("woff2"), url("source-sans-pro-v21-latin-300.66abf818.woff") format("woff"), url("source-sans-pro-v21-latin-300.c4579969.ttf") format("truetype"), url("source-sans-pro-v21-latin-300.96ee93ca.svg#SourceSansPro") format("svg");
  font-display: swap;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  src: url("source-sans-pro-v21-latin-regular.89ca6f5f.eot");
  src: local(""), url("source-sans-pro-v21-latin-regular.89ca6f5f.eot#iefix") format("embedded-opentype"), url("source-sans-pro-v21-latin-regular.14d85855.woff2") format("woff2"), url("source-sans-pro-v21-latin-regular.8db2995e.woff") format("woff"), url("source-sans-pro-v21-latin-regular.79126eb9.ttf") format("truetype"), url("source-sans-pro-v21-latin-regular.29674799.svg#SourceSansPro") format("svg");
  font-display: swap;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  src: url("source-sans-pro-v21-latin-700.41d8ebee.eot");
  src: local(""), url("source-sans-pro-v21-latin-700.41d8ebee.eot#iefix") format("embedded-opentype"), url("source-sans-pro-v21-latin-700.8e17c521.woff2") format("woff2"), url("source-sans-pro-v21-latin-700.44164331.woff") format("woff"), url("source-sans-pro-v21-latin-700.fc2d4e2c.ttf") format("truetype"), url("source-sans-pro-v21-latin-700.ec4bd4b4.svg#SourceSansPro") format("svg");
  font-display: swap;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Source Sans Pro, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 2000px) {
  .container {
    max-width: 2000px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(blockquote code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-sm :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-base :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose-base :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose-base :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose-base :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose-base :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose-base :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-base :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-lg {
  font-size: 1.125rem;
  line-height: 1.77778;
}

.prose-lg :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-lg :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  margin-top: 1.09091em;
  margin-bottom: 1.09091em;
  font-size: 1.22222em;
  line-height: 1.45455;
}

.prose-lg :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-left: 1em;
}

.prose-lg :where(h1):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .833333em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-lg :where(h2):not(:where([class~="not-prose"] *)) {
  margin-top: 1.86667em;
  margin-bottom: 1.06667em;
  font-size: 1.66667em;
  line-height: 1.33333;
}

.prose-lg :where(h3):not(:where([class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  font-size: 1.33333em;
  line-height: 1.5;
}

.prose-lg :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: .444444em;
  line-height: 1.55556;
}

.prose-lg :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
}

.prose-lg :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-lg :where(figcaption):not(:where([class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(code):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-lg :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .866667em;
}

.prose-lg :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-lg :where(pre):not(:where([class~="not-prose"] *)) {
  border-radius: .375rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1em 1.5em;
  font-size: .888889em;
  line-height: 1.75;
}

.prose-lg :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.55556em;
}

.prose-lg :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-lg :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .444444em;
}

.prose-lg :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-lg :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
}

.prose-lg :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 3.11111em;
  margin-bottom: 3.11111em;
}

.prose-lg :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(table):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
  line-height: 1.5;
}

.prose-lg :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-bottom: .75em;
  padding-left: .75em;
  padding-right: .75em;
}

.prose-lg :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .75em;
}

.prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-lg :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-lg :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-xl {
  font-size: 1.25rem;
  line-height: 1.8;
}

.prose-xl :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose-xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.2em;
  line-height: 1.5;
}

.prose-xl :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1.06667em;
}

.prose-xl :where(h1):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .857143em;
  font-size: 2.8em;
  line-height: 1;
}

.prose-xl :where(h2):not(:where([class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .888889em;
  font-size: 1.8em;
  line-height: 1.11111;
}

.prose-xl :where(h3):not(:where([class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.33333;
}

.prose-xl :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.8em;
  margin-bottom: .6em;
  line-height: 1.6;
}

.prose-xl :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-xl :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-xl :where(figcaption):not(:where([class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .9em;
  line-height: 1.55556;
}

.prose-xl :where(code):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
}

.prose-xl :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .861111em;
}

.prose-xl :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
}

.prose-xl :where(pre):not(:where([class~="not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.11111em 1.33333em;
  font-size: .9em;
  line-height: 1.77778;
}

.prose-xl :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  padding-left: 1.6em;
}

.prose-xl :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  padding-left: 1.6em;
}

.prose-xl :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .6em;
  margin-bottom: .6em;
}

.prose-xl :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .4em;
}

.prose-xl :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .4em;
}

.prose-xl :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .8em;
  margin-bottom: .8em;
}

.prose-xl :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
}

.prose-xl :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.2em;
}

.prose-xl :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.2em;
}

.prose-xl :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.2em;
}

.prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .8em;
  margin-bottom: .8em;
}

.prose-xl :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 2.8em;
  margin-bottom: 2.8em;
}

.prose-xl :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(table):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
  line-height: 1.55556;
}

.prose-xl :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-bottom: .888889em;
  padding-left: .666667em;
  padding-right: .666667em;
}

.prose-xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .888889em .666667em;
}

.prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-xl :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-xl :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.prose-2xl {
  font-size: 1.5rem;
  line-height: 1.66667;
}

.prose-2xl :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
}

.prose-2xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  margin-top: 1.06667em;
  margin-bottom: 1.06667em;
  font-size: 1.25em;
  line-height: 1.46667;
}

.prose-2xl :where(blockquote):not(:where([class~="not-prose"] *)) {
  margin-top: 1.77778em;
  margin-bottom: 1.77778em;
  padding-left: 1.11111em;
}

.prose-2xl :where(h1):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .875em;
  font-size: 2.66667em;
  line-height: 1;
}

.prose-2xl :where(h2):not(:where([class~="not-prose"] *)) {
  margin-top: 1.5em;
  margin-bottom: .833333em;
  font-size: 2em;
  line-height: 1.08333;
}

.prose-2xl :where(h3):not(:where([class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .666667em;
  font-size: 1.5em;
  line-height: 1.22222;
}

.prose-2xl :where(h4):not(:where([class~="not-prose"] *)) {
  margin-top: 1.66667em;
  margin-bottom: .666667em;
  line-height: 1.5;
}

.prose-2xl :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose-2xl :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-2xl :where(figcaption):not(:where([class~="not-prose"] *)) {
  margin-top: 1em;
  font-size: .833333em;
  line-height: 1.6;
}

.prose-2xl :where(code):not(:where([class~="not-prose"] *)) {
  font-size: .833333em;
}

.prose-2xl :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose-2xl :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-2xl :where(pre):not(:where([class~="not-prose"] *)) {
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.2em 1.6em;
  font-size: .833333em;
  line-height: 1.8;
}

.prose-2xl :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-left: 1.58333em;
}

.prose-2xl :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose-2xl :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .416667em;
}

.prose-2xl :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .833333em;
  margin-bottom: .833333em;
}

.prose-2xl :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.33333em;
}

.prose-2xl :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.33333em;
}

.prose-2xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .666667em;
  margin-bottom: .666667em;
}

.prose-2xl :where(hr):not(:where([class~="not-prose"] *)) {
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose-2xl :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(table):not(:where([class~="not-prose"] *)) {
  font-size: .833333em;
  line-height: 1.4;
}

.prose-2xl :where(thead th):not(:where([class~="not-prose"] *)) {
  padding-bottom: .8em;
  padding-left: .6em;
  padding-right: .6em;
}

.prose-2xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .8em .6em;
}

.prose-2xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose-2xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose-2xl :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-2xl :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-20 {
  bottom: 5rem;
}

.left-4 {
  left: 1rem;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.bottom-4 {
  bottom: 1rem;
}

.-bottom-28 {
  bottom: -7rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.row-start-1 {
  grid-row-start: 1;
}

.float-right {
  float: right;
}

.m-6 {
  margin: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mt-36 {
  margin-top: 9rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-5 {
  height: 1.25rem;
}

.h-\[50rem\] {
  height: 50rem;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.h-\[45rem\] {
  height: 45rem;
}

.h-20 {
  height: 5rem;
}

.h-56 {
  height: 14rem;
}

.h-10 {
  height: 2.5rem;
}

.h-0 {
  height: 0;
}

.h-6 {
  height: 1.5rem;
}

.max-h-\[80wh\] {
  max-height: 80wh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-\[40rem\] {
  max-height: 40rem;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.w-48 {
  width: 12rem;
}

.w-20 {
  width: 5rem;
}

.w-2\/5 {
  width: 40%;
}

.w-0 {
  width: 0;
}

.w-6 {
  width: 1.5rem;
}

.w-28 {
  width: 7rem;
}

.w-\[36rem\] {
  width: 36rem;
}

.w-96 {
  width: 24rem;
}

.w-64 {
  width: 16rem;
}

.w-56 {
  width: 14rem;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-max {
  max-width: max-content;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-\[12rem\] {
  max-width: 12rem;
}

.max-w-\[80vw\] {
  max-width: 80vw;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-screen-md {
  max-width: 768px;
}

.shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.-rotate-12 {
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-rows-\[20px\] {
  grid-auto-rows: 20px;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[1rem_1fr\] {
  grid-template-columns: 1rem 1fr;
}

.grid-cols-\[repeat\(auto-fill\,_minmax\(250px\,1fr\)\)\] {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.grid-cols-\[repeat\(auto-fill\,_minmax\(400px\,1fr\)\)\] {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-8 {
  gap: 2rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.border-8 {
  border-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-primary-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-primary-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.from-primary-900 {
  --tw-gradient-from: #0c4a6e;
  --tw-gradient-to: #0c4a6e00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-white {
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to);
}

.to-primary-700 {
  --tw-gradient-to: #0369a1;
}

.to-primary-600 {
  --tw-gradient-to: #0284c7;
}

.bg-cover {
  background-size: cover;
}

.bg-\[top_right_-18rem\] {
  background-position: right -18rem top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-top {
  object-position: top;
}

.object-left {
  object-position: left;
}

.object-center {
  object-position: center;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.p-2 {
  padding: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-light {
  font-weight: 300;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-secondary-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-transparent {
  --tw-shadow-color: transparent;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-primary-900 {
  --tw-shadow-color: #0c4a6e;
  --tw-shadow: var(--tw-shadow-colored);
}

.brightness-200 {
  --tw-brightness: brightness(2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.contrast-0 {
  --tw-contrast: contrast(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[max-height\] {
  transition-property: max-height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.bg-gradient-34d {
  background: linear-gradient(34deg, #fff 39%, #fff0 68%);
}

.font-cursive {
  font-family: Corinthia, cursive;
}

.bg-image-hero {
  background-image: url("hero.716bd604.png");
}

.webp .bg-image-hero {
  background-image: url("hero.d1ebdbac.webp");
}

.gallery .glide__slide:not(.glide__slide--active) a:before {
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gallery .glide__slide--active a:before {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gallery .glide__slide--active ~ .glide__slide a:before {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gallery .glide__bullet--active > div {
  padding: 1rem;
}

.gallery .glide__bullet--active img {
  height: 12rem;
}

#prodigy-powered.w-0.h-0 a {
  display: none;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  width: 1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-top-2:before {
  content: var(--tw-content);
  top: -.5rem;
}

.before\:-left-8:before {
  content: var(--tw-content);
  left: -2rem;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:right-0:before {
  content: var(--tw-content);
  right: 0;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-1\/3:before {
  content: var(--tw-content);
  top: 33.3333%;
}

.before\:-z-10:before {
  content: var(--tw-content);
  z-index: -10;
}

.before\:row-span-5:before {
  content: var(--tw-content);
  grid-row: span 5 / span 5;
}

.before\:mx-auto:before {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:h-24:before {
  content: var(--tw-content);
  height: 6rem;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:h-0\.5:before {
  content: var(--tw-content);
  height: .125rem;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:w-48:before {
  content: var(--tw-content);
  width: 12rem;
}

.before\:w-0\.5:before {
  content: var(--tw-content);
  width: .125rem;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:max-w-full:before {
  content: var(--tw-content);
  max-width: 100%;
}

.before\:translate-y-16:before {
  content: var(--tw-content);
  --tw-translate-y: 4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-12:before {
  content: var(--tw-content);
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-rotate-12:before {
  content: var(--tw-content);
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:scale-110:before {
  content: var(--tw-content);
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:scale-95:before {
  content: var(--tw-content);
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:scale-\[4\]:before {
  content: var(--tw-content);
  --tw-scale-x: 4;
  --tw-scale-y: 4;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:scale-y-75:before {
  content: var(--tw-content);
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:overflow-hidden:before {
  content: var(--tw-content);
  overflow: hidden;
}

.before\:rounded-lg:before {
  content: var(--tw-content);
  border-radius: .5rem;
}

.before\:bg-primary-900:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.before\:bg-gradient-to-t:before {
  content: var(--tw-content);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-r:before {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-tl:before {
  content: var(--tw-content);
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-b:before {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-tr:before {
  content: var(--tw-content);
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-bl:before {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.before\:from-primary-900:before {
  content: var(--tw-content);
  --tw-gradient-from: #0c4a6e;
  --tw-gradient-to: #0c4a6e00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-primary-700:before {
  content: var(--tw-content);
  --tw-gradient-from: #0369a1;
  --tw-gradient-to: #0369a100;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:to-primary-900:before {
  content: var(--tw-content);
  --tw-gradient-to: #0c4a6e;
}

.before\:to-primary-500:before {
  content: var(--tw-content);
  --tw-gradient-to: #0ea5e9;
}

.before\:to-primary-600:before {
  content: var(--tw-content);
  --tw-gradient-to: #0284c7;
}

.before\:shadow-md:before {
  content: var(--tw-content);
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:shadow-primary-900:before {
  content: var(--tw-content);
  --tw-shadow-color: #0c4a6e;
  --tw-shadow: var(--tw-shadow-colored);
}

.before\:transition-transform:before {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:bottom-2:after {
  content: var(--tw-content);
  bottom: .5rem;
}

.after\:-top-6:after {
  content: var(--tw-content);
  top: -1.5rem;
}

.after\:-left-12:after {
  content: var(--tw-content);
  left: -3rem;
}

.after\:mx-auto:after {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-px:after {
  content: var(--tw-content);
  height: 1px;
}

.after\:h-0\.5:after {
  content: var(--tw-content);
  height: .125rem;
}

.after\:h-0:after {
  content: var(--tw-content);
  height: 0;
}

.after\:h-48:after {
  content: var(--tw-content);
  height: 12rem;
}

.after\:h-8:after {
  content: var(--tw-content);
  height: 2rem;
}

.after\:w-0:after {
  content: var(--tw-content);
  width: 0;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:w-1\/2:after {
  content: var(--tw-content);
  width: 50%;
}

.after\:w-8:after {
  content: var(--tw-content);
  width: 2rem;
}

.after\:scale-x-0:after {
  content: var(--tw-content);
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-x-50:after {
  content: var(--tw-content);
  --tw-scale-x: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border-2:after {
  content: var(--tw-content);
  border-width: 2px;
}

.after\:border-primary-900:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.after\:bg-current:after {
  content: var(--tw-content);
  background-color: currentColor;
}

.after\:bg-primary-900:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:bg-gradient-to-r:after {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.after\:from-primary-900:after {
  content: var(--tw-content);
  --tw-gradient-from: #0c4a6e;
  --tw-gradient-to: #0c4a6e00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-transparent:after {
  content: var(--tw-content);
  --tw-gradient-to: transparent;
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:transition-transform:after {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-primary-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.hover\:text-secondary-600:hover {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.hover\:text-secondary-800:hover {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:before\:scale-100:hover:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:after\:w-full:hover:after {
  content: var(--tw-content);
  width: 100%;
}

.hover\:after\:scale-x-75:hover:after {
  content: var(--tw-content);
  --tw-scale-x: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:brightness-100 {
  --tw-brightness: brightness(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.group:hover .group-hover\:contrast-100 {
  --tw-contrast: contrast(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.prose-a\:text-primary-900 :is(:where(a):not(:where([class~="not-prose"] *))) {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.prose-a\:text-secondary-400 :is(:where(a):not(:where([class~="not-prose"] *))) {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.prose-a\:transition-colors :is(:where(a):not(:where([class~="not-prose"] *))) {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:prose-a\:text-secondary-600 :is(:where(a):not(:where([class~="not-prose"] *))):hover {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.hover\:prose-a\:text-secondary-200 :is(:where(a):not(:where([class~="not-prose"] *))):hover {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.prose-img\:mx-auto :is(:where(img):not(:where([class~="not-prose"] *))) {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .sm\:-bottom-16 {
    bottom: -4rem;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:row-start-1 {
    grid-row-start: 1;
  }

  .sm\:row-end-4 {
    grid-row-end: 4;
  }

  .sm\:max-w-\[30vw\] {
    max-width: 30vw;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:grid-cols-\[1fr_2rem_1fr\] {
    grid-template-columns: 1fr 2rem 1fr;
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:gap-x-8 {
    column-gap: 2rem;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:overflow-visible {
    overflow: visible;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-justify {
    text-align: justify;
  }

  .sm\:before\:-right-8:before {
    content: var(--tw-content);
    right: -2rem;
  }

  .sm\:before\:left-\[unset\]:before {
    content: var(--tw-content);
    left: unset;
  }

  .sm\:before\:col-start-2:before {
    content: var(--tw-content);
    grid-column-start: 2;
  }

  .sm\:before\:row-span-4:before {
    content: var(--tw-content);
    grid-row: span 4 / span 4;
  }

  .sm\:before\:mx-auto:before {
    content: var(--tw-content);
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:before\:translate-y-10:before {
    content: var(--tw-content);
    --tw-translate-y: 2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:before\:justify-self-center:before {
    content: var(--tw-content);
    justify-self: center;
  }

  .sm\:before\:bg-gradient-to-l:before {
    content: var(--tw-content);
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }

  .sm\:after\:-right-16:after {
    content: var(--tw-content);
    right: -4rem;
  }

  .sm\:after\:left-\[unset\]:after {
    content: var(--tw-content);
    left: unset;
  }

  .sm\:after\:-left-16:after {
    content: var(--tw-content);
    left: -4rem;
  }

  .sm\:after\:col-start-1:after {
    content: var(--tw-content);
    grid-column-start: 1;
  }

  .sm\:after\:mx-auto:after {
    content: var(--tw-content);
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:after\:from-transparent:after {
    content: var(--tw-content);
    --tw-gradient-from: transparent;
    --tw-gradient-to: #0000;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }

  .sm\:after\:via-primary-900:after {
    content: var(--tw-content);
    --tw-gradient-to: #0c4a6e00;
    --tw-gradient-stops: var(--tw-gradient-from), #0c4a6e, var(--tw-gradient-to);
  }
}

@media (min-width: 768px) {
  .md\:prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  .md\:prose-xl :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .md\:prose-xl :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.2em;
    line-height: 1.5;
  }

  .md\:prose-xl :where(blockquote):not(:where([class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1.06667em;
  }

  .md\:prose-xl :where(h1):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .857143em;
    font-size: 2.8em;
    line-height: 1;
  }

  .md\:prose-xl :where(h2):not(:where([class~="not-prose"] *)) {
    margin-top: 1.55556em;
    margin-bottom: .888889em;
    font-size: 1.8em;
    line-height: 1.11111;
  }

  .md\:prose-xl :where(h3):not(:where([class~="not-prose"] *)) {
    margin-top: 1.6em;
    margin-bottom: .666667em;
    font-size: 1.5em;
    line-height: 1.33333;
  }

  .md\:prose-xl :where(h4):not(:where([class~="not-prose"] *)) {
    margin-top: 1.8em;
    margin-bottom: .6em;
    line-height: 1.6;
  }

  .md\:prose-xl :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .md\:prose-xl :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .md\:prose-xl :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .md\:prose-xl :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:prose-xl :where(figcaption):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .9em;
    line-height: 1.55556;
  }

  .md\:prose-xl :where(code):not(:where([class~="not-prose"] *)) {
    font-size: .9em;
  }

  .md\:prose-xl :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: .861111em;
  }

  .md\:prose-xl :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: .9em;
  }

  .md\:prose-xl :where(pre):not(:where([class~="not-prose"] *)) {
    border-radius: .5rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1.11111em 1.33333em;
    font-size: .9em;
    line-height: 1.77778;
  }

  .md\:prose-xl :where(ol):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .md\:prose-xl :where(ul):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
    margin-bottom: 1.2em;
    padding-left: 1.6em;
  }

  .md\:prose-xl :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: .6em;
    margin-bottom: .6em;
  }

  .md\:prose-xl :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .md\:prose-xl :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: .4em;
  }

  .md\:prose-xl :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .md\:prose-xl :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .md\:prose-xl :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .md\:prose-xl :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.2em;
  }

  .md\:prose-xl :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.2em;
  }

  .md\:prose-xl :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: .8em;
    margin-bottom: .8em;
  }

  .md\:prose-xl :where(hr):not(:where([class~="not-prose"] *)) {
    margin-top: 2.8em;
    margin-bottom: 2.8em;
  }

  .md\:prose-xl :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-xl :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-xl :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-xl :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-xl :where(table):not(:where([class~="not-prose"] *)) {
    font-size: .9em;
    line-height: 1.55556;
  }

  .md\:prose-xl :where(thead th):not(:where([class~="not-prose"] *)) {
    padding-bottom: .888889em;
    padding-left: .666667em;
    padding-right: .666667em;
  }

  .md\:prose-xl :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .md\:prose-xl :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .md\:prose-xl :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
    padding: .888889em .666667em;
  }

  .md\:prose-xl :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .md\:prose-xl :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .md\:prose-xl :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .md\:prose-xl :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:z-20 {
    z-index: 20;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mr-16 {
    margin-right: 4rem;
  }

  .md\:grid {
    display: grid;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:columns-2 {
    columns: 2;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:bg-top {
    background-position: top;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pl-96 {
    padding-left: 24rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:bg-gradient-54d {
    background: linear-gradient(54deg, #fff 39%, #fff0 64%);
  }

  .md\:before\:hidden:before {
    content: var(--tw-content);
    display: none;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-36 {
    margin-top: 9rem;
  }

  .lg\:max-h-\[30rem\] {
    max-height: 30rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:before\:translate-y-24:before {
    content: var(--tw-content);
    --tw-translate-y: 6rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (min-width: 1280px) {
  .xl\:prose-lg {
    font-size: 1.125rem;
    line-height: 1.77778;
  }

  .xl\:prose-lg :where(p):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
  }

  .xl\:prose-lg :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
    margin-top: 1.09091em;
    margin-bottom: 1.09091em;
    font-size: 1.22222em;
    line-height: 1.45455;
  }

  .xl\:prose-lg :where(blockquote):not(:where([class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: 1.66667em;
    padding-left: 1em;
  }

  .xl\:prose-lg :where(h1):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: .833333em;
    font-size: 2.66667em;
    line-height: 1;
  }

  .xl\:prose-lg :where(h2):not(:where([class~="not-prose"] *)) {
    margin-top: 1.86667em;
    margin-bottom: 1.06667em;
    font-size: 1.66667em;
    line-height: 1.33333;
  }

  .xl\:prose-lg :where(h3):not(:where([class~="not-prose"] *)) {
    margin-top: 1.66667em;
    margin-bottom: .666667em;
    font-size: 1.33333em;
    line-height: 1.5;
  }

  .xl\:prose-lg :where(h4):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: .444444em;
    line-height: 1.55556;
  }

  .xl\:prose-lg :where(img):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .xl\:prose-lg :where(video):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .xl\:prose-lg :where(figure):not(:where([class~="not-prose"] *)) {
    margin-top: 1.77778em;
    margin-bottom: 1.77778em;
  }

  .xl\:prose-lg :where(figure > *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:prose-lg :where(figcaption):not(:where([class~="not-prose"] *)) {
    margin-top: 1em;
    font-size: .888889em;
    line-height: 1.5;
  }

  .xl\:prose-lg :where(code):not(:where([class~="not-prose"] *)) {
    font-size: .888889em;
  }

  .xl\:prose-lg :where(h2 code):not(:where([class~="not-prose"] *)) {
    font-size: .866667em;
  }

  .xl\:prose-lg :where(h3 code):not(:where([class~="not-prose"] *)) {
    font-size: .875em;
  }

  .xl\:prose-lg :where(pre):not(:where([class~="not-prose"] *)) {
    border-radius: .375rem;
    margin-top: 2em;
    margin-bottom: 2em;
    padding: 1em 1.5em;
    font-size: .888889em;
    line-height: 1.75;
  }

  .xl\:prose-lg :where(ol):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .xl\:prose-lg :where(ul):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
    margin-bottom: 1.33333em;
    padding-left: 1.55556em;
  }

  .xl\:prose-lg :where(li):not(:where([class~="not-prose"] *)) {
    margin-top: .666667em;
    margin-bottom: .666667em;
  }

  .xl\:prose-lg :where(ol > li):not(:where([class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .xl\:prose-lg :where(ul > li):not(:where([class~="not-prose"] *)) {
    padding-left: .444444em;
  }

  .xl\:prose-lg :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .xl\:prose-lg :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .xl\:prose-lg :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .xl\:prose-lg :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 1.33333em;
  }

  .xl\:prose-lg :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 1.33333em;
  }

  .xl\:prose-lg :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
    margin-top: .888889em;
    margin-bottom: .888889em;
  }

  .xl\:prose-lg :where(hr):not(:where([class~="not-prose"] *)) {
    margin-top: 3.11111em;
    margin-bottom: 3.11111em;
  }

  .xl\:prose-lg :where(hr + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-lg :where(h2 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-lg :where(h3 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-lg :where(h4 + *):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-lg :where(table):not(:where([class~="not-prose"] *)) {
    font-size: .888889em;
    line-height: 1.5;
  }

  .xl\:prose-lg :where(thead th):not(:where([class~="not-prose"] *)) {
    padding-bottom: .75em;
    padding-left: .75em;
    padding-right: .75em;
  }

  .xl\:prose-lg :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-lg :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-lg :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
    padding: .75em;
  }

  .xl\:prose-lg :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
    padding-left: 0;
  }

  .xl\:prose-lg :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
    padding-right: 0;
  }

  .xl\:prose-lg :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
    margin-top: 0;
  }

  .xl\:prose-lg :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
    margin-bottom: 0;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:bg-gradient-59d {
    background: linear-gradient(59deg, #fff 39%, #fff0 62%);
  }
}

@media (min-width: 2000px) {
  .\33 xl\:bg-gradient-54d {
    background: linear-gradient(54deg, #fff 39%, #fff0 64%);
  }
}

/*# sourceMappingURL=index.5d922f57.css.map */
